import React from 'react';

const Section = ({ title, children }) => (
  <div className="mb-12">
    <h2 className="text-2xl font-bold mb-2 text-gray-800">{title}</h2>
    <div>
      {children}
    </div>
  </div>
);

const Experience = ({ company, role, details }) => (
  <div>
    <div className="mb-6">
      <h3 className="text-xl font-semibold text-gray-700 mb-1">{company}</h3>
      <p className="text-lg text-gray-600 mb-1">{role}</p>
      <ul className="list-disc pl-6 space-y-2">
        {details.map((detail, index) => (
          <li key={index} className="text-gray-600">{detail}</li>
        ))}
      </ul>
    </div>
  </div>
);

const Education = ({ school, details }) => (
  <div className="mb-4">
    <h3 className="text-xl font-semibold text-gray-700 mb-1">{school}</h3>
    <ul className="list-disc pl-6 space-y-2">
      {details.map((detail, index) => (
        <li key={index} className="text-gray-600">{detail}</li>
      ))}
    </ul>
  </div>
);

const PersonalWebsite = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="sticky top-0 z-10 bg-white shadow-sm">
        <div className="max-w-4xl mx-auto px-12 py-4">
          <h1 className="text-3xl font-bold text-gray-900">Teddy Marchildon</h1>
        </div>
      </nav>

      <main className="max-w-4xl mx-auto px-12 py-12">
        <p className="text-lg text-gray-600 mb-12">
          Company operator. Passionate about building great products and leading high-performing teams.
        </p>

        <Section title="Work Experience">
          <Experience
            company={
              <>
                <a
                  href="https://www.checkhq.com/"
                  className="text-blue-600 hover:text-blue-800 underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Check Technologies
                </a>
                , 2021-present
              </>
            }
            role="Senior Engineer, Engineering Manager"
            details={[
              "Joined as an early engineer. Built many core API resources and established products and services critical to company success.",
              <>
                Grew into Engineering Management. I lead a team of engineers and a cross functional pod owning Check's{' '}
                <a
                  href="https://www.checkhq.com/platform/payroll/embedded-setup"
                  className="text-blue-600 hover:text-blue-800 underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Embedded Setup offering
                </a>
              </>,
              "Lead planning and execution of key strategic initiatives"
            ]}
          />
          <Experience
            company="Meta, 2019-2021"
            role="Software Engineer L3, L4"
            details={[
              "Software engineer on the Profile team",
              "One of a few dozen engineers across the company on the TreeHuggers team, monitoring continuous deploys across the family of apps for regressions"
            ]}
          />
        </Section>

        <hr className="border-gray-200 my-8 w-1/2 mx-auto" />

        <Section title="Internship Experience">
          <Experience
            company="Facebook"
            role="Software Engineering Intern"
            details={["Contributed to business tools in Pages"]}
          />
          <Experience
            company="Barclays"
            role="Trading Intern"
            details={["Worked on the equity electronic trading team", "Built an algorithm to monitor real time stock quotes across several different vendors, which was later used as input to trade execution strategies."]}
          />
          <Experience
            company="WillowTree Apps"
            role="Software Development Intern"
            details={["Developed mobile applications"]}
          />
        </Section>

        <hr className="border-gray-200 my-8 w-1/2 mx-auto" />

        <Section title="Education">
          <Education
            school="Duke University, class of 2019"
            details={[
              "B.S. Computer Science, Minor in Statistics",
              "Teaching Assistant for CS courses",
              "Taught courses during HackDuke"
            ]}
          />
        </Section>
      </main>

      <footer className="bg-white border-t">
        <div className="max-w-4xl mx-auto px-12 py-8">
          <p className="text-center text-gray-600">
            © {new Date().getFullYear()} Teddy Marchildon
          </p>
        </div>
      </footer>
    </div>
  );
};

export default PersonalWebsite;
