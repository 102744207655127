import PersonalWebsite from "./About";

function App() {
  return (
    <div>
      <PersonalWebsite />
    </div>
  );
}

export default App;
